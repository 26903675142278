
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: { AppLogoOnly },
  directives: { maska },
})
export default class ResetPasswordForm extends Vue {
  form = {
    new_password: '',
    confirm_password: '',
  }

  token!: string

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get requiredRule() {
    const requiredRule = {
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.doReset()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doReset() {
    const { dispatch } = this.$store

    await dispatch(ACTION_PROFILE.RESET_PASSWORD, {
      password: this.form.new_password,
      token: this.token,
    }).catch((error: Error) => {
      errorHandler(error)
    })

    this.onGotoLogin()
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }

  private created() {
    this.token = this.$route.params.token
    // Check token valid
    this.$store.dispatch(ACTION_PROFILE.CHECK_PASSWORD_TOKEN, {
      token: this.token,
    })
  }
}
