<template>
  <div class="login-form">
    <q-card class="q-pa-lg">
      <q-card-section>
        <div class="text-center">
          <AppLogoOnly />
        </div>
      </q-card-section>

      <q-form ref="formRef">
        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.password]"
          outlined
          v-model="form.new_password"
          dense
          autocomplete="input_your_password"
          :placeholder="$t('label.new_password')"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-input>

        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0 && val === form.new_password) || requiredRule.password_again]"
          outlined
          v-model="form.confirm_password"
          dense
          autocomplete="confirm_your_password"
          :placeholder="$t('label.confirm_password')"
        >
          <template v-slot:prepend>
            <q-icon name="lock_open" />
          </template>
        </q-input>
        <div class="row no-wrap items-center">
          <q-btn icon="arrow_back" flat color="primary" @click="onGotoLogin"> {{ $t('login') }} </q-btn>
          <q-space />
          <q-btn color="primary" @click="onSubmit"> {{ $t('label.confirm') }}</q-btn>
        </div>
        <q-separator class="q-my-md" />
      </q-form>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: { AppLogoOnly },
  directives: { maska },
})
export default class ResetPasswordForm extends Vue {
  form = {
    new_password: '',
    confirm_password: '',
  }

  token!: string

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get requiredRule() {
    const requiredRule = {
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }

        this.doReset()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doReset() {
    const { dispatch } = this.$store

    await dispatch(ACTION_PROFILE.RESET_PASSWORD, {
      password: this.form.new_password,
      token: this.token,
    }).catch((error: Error) => {
      errorHandler(error)
    })

    this.onGotoLogin()
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }

  private created() {
    this.token = this.$route.params.token
    // Check token valid
    this.$store.dispatch(ACTION_PROFILE.CHECK_PASSWORD_TOKEN, {
      token: this.token,
    })
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
}
</style>
