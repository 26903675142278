<template>
  <ResetPasswordForm />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import ResetPasswordForm from '@/components/account/ResetPasswordForm.vue'

@Options({
  components: { ResetPasswordForm },
  directives: { maska },
})
export default class ResetPassword extends Vue {}
</script>
